import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'cynteka-chat',
    loadChildren: () => import('./cynteka-chat/cynteka.chat.module').then(m => m.CyntekaChatModule)
  },
  {
    path: 'cynteka-desktop',
    loadChildren: () => import('./cynteka-desktop/cynteka.desktop.module').then(m => m.CyntekaDesktopModule)
  },
  {
    path: 'cynteka-mobile',
    loadChildren: () => import('./cynteka-mobile/cynteka.mobile.module').then(m => m.CyntekaMobileModule)
  },
  {
    path: 'zakupay-mobile',
    loadChildren: () => import('./zakupay-mobile/zakupay.mobile.module').then(m => m.ZakupayMobileModule)
  },
  {
    path: 'zakupay-desktop',
    loadChildren: () => import('./zakupay-desktop/zakupay.desktop.module').then(m => m.ZakupayDesktopModule)
  },
  {
    path: 'prodavay-desktop',
    loadChildren: () => import('./prodavay-desktop/prodavay.desktop.module').then(m => m.ProdavayDesktopModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
