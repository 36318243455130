import {Injectable, OnDestroy} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";
import {optionTableUtil} from "../../_helpers/option-table-util";
import {CommonUtils} from "../../../util/commonUtils";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl
    implements OnDestroy {

    unsubscribe: Subject<void> = new Subject<void>();
    OF_LABEL = 'of';

    constructor(private translateService: TranslateService) {
        super();
        this.translateService.use(CommonUtils.getLangFromCookie());
        this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this.translateService
            .get([
                'paginator.itemsPerPage',
                'paginator.nextPage',
                'paginator.previousPage',
                'paginator.ofLabel',
                'paginator.lastPage',
                'paginator.firstPage'
            ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(translation => {
                this.itemsPerPageLabel =
                    translation['paginator.itemsPerPage'];
                this.nextPageLabel = translation['paginator.nextPage'];
                this.previousPageLabel =
                    translation['paginator.previousPage'];
                this.OF_LABEL = translation['paginator.ofLabel'];
                this.lastPageLabel = translation['paginator.lastPage'];
                this.firstPageLabel = translation['paginator.firstPage'];
                this.changes.next();
            });
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${
            this.OF_LABEL
        } ${length}`;
    };
}