import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spa2';

  constructor(private router: Router, private http: HttpClient) {}

  private lastLoginDate = localStorage.getItem('lastLoginDate');

  private checkUserChanged(): void {
    if (this.lastLoginDate !== localStorage.getItem('lastLoginDate')) {
      this.lastLoginDate = localStorage.getItem('lastLoginDate')
      window.location.reload()
    }
  }

  ngOnInit(): void {
    //Ререндерить страницу, если в роуте сменился параметр
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //Очищать localStorage при новой версии
    this.http.get('/core/version?simple=true').subscribe((version: string) => {
      let localVersion = localStorage.getItem("cyntekaVersion");
      if(!localVersion || localVersion !== '' + version ){
        this.exclusiveDeleteLocalStorage();
        localStorage.setItem('cyntekaVersion', version);
      }
    })

    document.addEventListener('focus', () => this.checkUserChanged())
    document.addEventListener('visibilitychange', () => this.checkUserChanged())
  }

  private exclusiveDeleteLocalStorage() {
    for (let key in localStorage) {
      if (!(key.toLowerCase().includes('filter') && key.toLowerCase().includes('cyntekamobile')) &&
        !key.toLowerCase().includes('hashfilterstate') &&
        !key.toLowerCase().includes('lasthashstate') &&
        key !== 'lastSeenPosts') {
        delete localStorage[key];
      }
    }
  }


}
export const NON_REDIRECT_PATHS = ["reset-password", "registration", "login",
  "send_confirmation", "send_reset", "reset_password"];
